import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { Stack, Box, Grid, Paper, Typography, Button, Divider} from '@mui/material';
import { ImageSlider } from '../components/ImageSlider';
import Quote from '../components/Quote';
import Banner from '../components/Banner'
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';
import TestimonialList from '../components/TestimonialList';


const images = [
    {url: '/images/slides/slide1.jpg', title: 'image-1', 'position': 'center center'},
    {url: '/images/slides/slide2.jpg', title: 'image-2', 'position': 'center center' },
    {url: '/images/slides/slide3.jpg', title: 'image-3', 'position': 'center center'},   
    {url: '/images/slides/slide4.jpg', title: 'image-4','position': 'center center'},
    {url: '/images/slides/slide5.jpg', title: 'image-5','position': 'center center'},
    {url: '/images/slides/slide6.jpg', title: 'image-6','position': 'center center'},
    {url: '/images/slides/slide7.jpg', title: 'image-7','position': 'center center'},
    {url: '/images/slides/slide8.jpg', title: 'image-8','position': 'center center'},
    {url: '/images/slides/slide9.jpg', title: 'image-9','position': 'center center'}
];

const containerStyles = {
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    background:'transparent'
}

// const christmasProps = {
//     image: 'headers/christmasHeader.jpg',
//     imgWidth: '12', 
//     url: '/on-sale', 
// }

const aboutMeProps = {
    // image: 'aboutMe-thumb.jpg',
    title: 'THE SAGE DOOR',
    text: "The Sage Door started as not only a dream, but a true calling. Creating cozy " +
    "spaces has been a passion of mine since I purchased my first home in 2015. " + 
    "I fell in love with how small details could truly elevate a house and turn it into a home. " +
    "You will find that I'm a sucker for neutrals (with a touch of green, of course) " +
    "and all about adding texture and warmth to a space. My goal is to remain timeless, not too trendy, " + 
    "with a mix of different styles.",
    imgWidth: '0', 
    btn: 'Read more',
    url: '/about',
    contentBGColor: 'rgb(233,229,213,.30)',
    color: '#383838',
    align: 'left'
}

const testimonialProps = {
    // image: 'aboutMe-thumb.jpg',
    title: 'Add Your Testimonial',
    text: "We would love to hear from you. Your feedback is crictical to our success.",
    imgWidth: '0', 
    btn: 'Add a Testimonial',
    url: '/testimonials',
    image:'books.jpg',
    imgWidth:'3',
    contentBGColor: 'rgb(166,179,150,.50)',
    color: '#383838',
    align: 'left'
}


export default function Home() {

  return (
    <>
        <ResponsiveAppBar/>
        <div className="contentWrapper">
            
            <Stack sx={{height:{xs:'300px', lg:'550px'}, ...containerStyles}}>
                <ImageSlider slides={images}/>
            </Stack>

            <Stack sx={{height:'auto', margin:{xs:'40px auto', md:'80px auto'}, padding:'0px 30px'}}>
                <Quote text="For every house is built by someone, but God is the builder of everything."  subText="Isaiah 28:16" />
            </Stack>

            {/* <Stack sx={{height:'auto', marginTop:{xs:'0px', md:'75px'}}}> 
                <Banner  {...christmasProps} />
            </Stack>  */}

           
            <Box sx={{marginTop:{xs:'50px', md:'75px'}}}>
                <Grid container justifyContent="center" alignItems="center" sx={{ width:'auto', margin:'0 auto'}}>        
                    
                    <Grid item xs={6} md={3} sx={{padding:{xs:'0px 10px 0 20px', md:'0 30px 0 0'}}}>
                        <Box sx={{backgroundSize:'cover',
                            backgroundPosition:'30% 75%',
                            backgroundImage: `url(/images/features/feature1.jpg)`,  
                            cursor: 'pointer',
                            height: {xs: '175px', md:'275px'}}} 
                            onClick = {() => window.location.href = "/viewProduct/106" } />
                    </Grid>
                     
                    <Grid item xs={6} md={3} sx={{padding:{xs:'0px 20px 0 10px', md:'0 20px 0 10px'}}}>
                        <Box sx={{backgroundSize:'cover',
                            backgroundPosition:'50% 40%',
                            backgroundImage: `url(/images/features/feature2.jpg)`,  
                            cursor: 'pointer',
                            height: {xs: '175px', md:'275px'}}} 
                            onClick = {() => window.location.href = "/viewProduct/1" } />
                    </Grid>

                    <Grid item xs={6} md={3} sx={{padding:{xs:'20px 10px 0 20px', md:'0 10px 0 20px'}}}>
                        <Box sx={{backgroundSize:'cover',
                            backgroundPosition:'50% 40%',
                            backgroundImage: `url(/images/features/feature8.jpg)`,  
                            cursor: 'pointer',
                            height: {xs: '175px', md:'275px'}}} 
                            onClick = {() => window.location.href = "/viewProduct/10" } />
                    </Grid>

                    <Grid item xs={6} md={3} sx={{padding:{xs:'20px 20px 0 10px', md:'0 0 0 30px'}}}>
                        <Box sx={{backgroundSize:'cover',
                            backgroundPosition:'50% 40%',
                            backgroundImage: `url(/images/features/feature9.jpg)`,  
                            cursor: 'pointer',
                            height: {xs: '175px', md:'275px'}}}  
                            onClick = {() => window.location.href = "/viewProduct/34" } />
                    </Grid>
                    
                </Grid>
            </Box> 
            
            
            <Stack sx={{height:'auto', marginTop: {xs:'50px', md:'75px'}, marginBottom:'50px'}}> 
                <Banner {...aboutMeProps}/>
            </Stack>

           
            <Stack sx={{height:'auto', marginTop:{xs:'0px', md:'75px'}}}> 
                
                    <Box >
                        <Typography sx={{fontWeight:'bold', textAlign:'center', fontSize:'2em', color:"#383838"}}>
                            What Our Customers Say About Us
                        </Typography>
                    </Box>
                    
                    <Divider sx={{borderColor:'#f5f5f5', width:{xs:'100%', sm:'80%'}, marginTop:'20px'}}/>
                    
                    <TestimonialList limit={3} sx={{marginTop:{xs:'0', sm:'20px'}}}/>
                    
                    <Box sx={{textAlign:'center', paddingBottom:'20px'}}>
                        <Button className="sage" component={Link} to="/testimonials">View Testimonials</Button>
                    </Box>
            </Stack>      
        </div>
        <Footer />
    </>
  );
}

