import React, {} from 'react'
import { Stack, Button } from '@mui/material'

export default function CategoryNav() {

    return (
        <>
            <Stack xs={12} sx={{fontSize:'1em', color:'#484848', fontWeight:'bold', marginTop:'30px', padding:'10px 0', borderBottom:'1px solid #f0f0f0'}}>
                Categories
            </Stack>
            <Stack>
                <Button
                    xs={12}
                    key='cat-bath'
                    href='/bath'
                    className='catLink'>
                    Bath + Body
                </Button>
                <Button
                    xs={12}
                    key='cat-books'
                    href='/books'
                    className='catLink'>
                    Books + Stationery
                </Button>
                <Button
                    key='cat-laundry'
                    href='/cleaning'
                    className='catLink'>
                    Cleaning
                </Button>
                <Button
                    key='cat-decor'
                    href='/decor'
                    className='catLink'>
                    Decor
                </Button>
                <Button
                    key='food'
                    href='/food'
                    className='catLink'>
                    Food + Drink
                </Button>
                <Button
                    key='cat-fragrances'
                    href='/fragrances'
                    className='catLink'>
                    Fragrances
                </Button>
                <Button
                    key='cat-kitchen'
                    href='/kitchen'
                    className='catLink'>
                    Kitchen
                </Button>
                
                
            </Stack>
        </>
    )
}