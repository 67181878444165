import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, MenuItem, Menu, IconButton, Toolbar, AppBar, Box, Stack, Drawer, Badge, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCart } from '../contexts/CartContext';
import Bar from './Bar';


const pages = [
  {text:'Bath + Body', path:'/bath'}, 
  {text:'Books + Stationery', path:'/books'},  
  {text: 'Cleaning', path:'/cleaning'},
  {text:'Decor', path:'/decor'}, 
  {text: 'Food + Drink', path:'/food'},
  {text:'Fragrances', path:'/fragrances'}, 
  {text:'Kitchen', path:'/kitchen'}
  
  
];


function ResponsiveAppBar() {

  const { cartQuantity, openCart } = useCart();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };



  return (
    <>
    <Bar/>
    <AppBar position="static" elevation={0} sx={{background:'transparent', borderBottom:'1px solid #e9e5d5', marginTop:'20px'}}>
      <div style={{width:'100%'}}>    
        <Toolbar disableGutters sx={{width:'90%', marginLeft:'5%', padding:'20px 0'}}>

        
        
        <Grid container sx={{flexGrow:1, textAlign:'center', justifyContent:"center", alignItems:'center'}}>
          {/*****************Hamburger**********************/}
          <Grid item sx={{display: {xs:'flex', md:'none'}}} sm={2}>
              <IconButton
                size="large"
                onClick={handleDrawer}
                color='#a6b396'
                sx={{border:'0 !important'}}>
                <MenuIcon />
              </IconButton>

              <Drawer
                id="menu-appbar"
                open={isDrawerOpen}
                onClick={handleDrawer}
                PaperProps={{sx:{padding:"40px", elevation:1, width:{xs:'calc(100% - 80px)'}, height:'calc(100% - 80px)', overflow:'hidden', position:'relative'}}} >
                
                <Stack sx={{overflowY:'scroll', height:'auto', minHeight:'100%'}}>                
                  
                    <img src="/images/logo2.jpg" style={{maxHeight:"100px", margin:'0 auto'}}/>
                    <Box sx={{marginTop:'15px'}}>
                      {pages.map((page) => (
                        <Link style={{textDecoration:"none", color:"#989898"}} to={page.path}>
                          <MenuItem key={page} sx={{padding:'10px', borderBottom:'1px solid #f0f0f0', fontSize:'1.1em', color: page.color ? page.color : "#989898"}}>
                            {page.text}
                          </MenuItem>
                        </Link>
                      ))}
                      <Link style={{textDecoration:"none", color:"#989898"}} to="#"onClick={(e) => {e.preventDefault(); window.location.href='mailto:info@thesagedoor.com'; }}>
                        <MenuItem key="email" sx={{padding:'10px', borderBottom:'1px solid #f0f0f0', fontSize:'1.1em'}}>
                          Contact Us
                        </MenuItem>
                      </Link>

                      <Link style={{textDecoration:"none",   margin:'0 auto' }} to="#" onClick={handleDrawer}>
                        <MenuItem key="email" sx={{padding:'10px',  fontSize:'1.1em', color:"#a6b396", fontWeight:'500', justifyContent:'center'}}>
                          CLOSE
                        </MenuItem>
                      </Link>
                    </Box>
              
                </Stack>
            
              </Drawer>
          </Grid>

          <Grid item sx={{display:{xs:'none', md:'flex'}}} sm={2}></Grid>

          <Grid item xs={8} sx={{justifyContent:'center', alignItems:'center'}}>
            <Link to="/"><img src="/images/logo2.jpg" style={{maxHeight:"140px", margin:'0 auto'}}/></Link>
          </Grid>   

          <Grid item xs={2} >        
            <Link to ="/cart">
              <Badge badgeContent={cartQuantity} color="secondary" showZero>
                <ShoppingCartIcon  color="action"/>
              </Badge>
            </Link>
          </Grid>

        </Grid>        
        </Toolbar>

        {/*****************Normal Links**********************/}
        <Box sx={{display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems:'center', background:'rgb(233,229,213,.85)'}}>
            {pages.map((page) => (
              <Button
                key={page.text}
                href={page.path}
                className='navLink'
               >
                {page.text}
              </Button>
            ))}
          </Box>
      </div>     
    </AppBar>
    </>
     
    
  );
}
export default ResponsiveAppBar;
